import React, { Fragment, useState } from "react"
import { Link, graphql } from "gatsby"
import NewsEventList from "../layouts/news-event-template"
import CardNewsEvent from "../components/custom-components/cardNewsEvent"
import "../css/custom-css/custom-news.css"
import Seo from "../components/seo"
import { useIntl } from "gatsby-plugin-intl"

const BlogGrid3Sidebar = props => {
  const intl = useIntl()
  const locale = intl.locale !== "en" ? `/${intl.locale}` : ""

  // {search}
  const { data } = props
  const [searchState, setSearchState] = useState(false)

  const [state, setState] = useState({
    filteredData: [],
    query: "",
  })

  const handleInputChange = event => {
    const query = event.target.value
    if (query === "") {
      setSearchState(false)
    } else {
      const posts = intl.locale === "cn" ? data.getAllNewsEventsCn.edges : intl.locale === "en" || intl.locale === "id" ? data.getAllNewsEvents.edges : []
      const filteredData = posts.filter(post => {
        const { title } = post.node
        return title.toLowerCase().includes(query.toLowerCase())
      })

      setState({
        query,
        filteredData,
      })
      setSearchState(true)
    }
  }

  // {pagination}
  const { currentPage, newsEventNumPages } = props.pageContext
  // console.log(props.pageContext)
  const prevPage =
    currentPage - 1 === 1
      ? `${locale}/news-page/news-event`
      : `${locale}/news-page/news-event${currentPage - 1}`
  const nextPage = `${locale}/news-page/news-event${currentPage + 1}`

  const isFirst = currentPage === 1
  const isLast = currentPage === newsEventNumPages

  return (
    <Fragment>
      <Seo title={intl.formatMessage({ id: "header.newsandevents" })} />

      <NewsEventList handleInputChange={handleInputChange}>
        <div className="row">
            {searchState
            ? state.filteredData.map(({ node }) => {
                return <CardNewsEvent key={data.id} data={node} />
              })
            : locale === "/cn" ? data.newsEventsCnPost.edges.map(({ node }) => {
              return <CardNewsEvent key={data.id} data={node} />
            })
            : data.newsEventsPost.edges.map(({ node }) => {
                return <CardNewsEvent key={data.id} data={node} />
              })}
        </div>

        <div className="pagination-bx clearfix m-b30 text-center col-md-12">
          {!searchState && (
            <ul className="pagination">
              <li className="previous">
                {!isFirst && (
                  <Link to={prevPage}>
                    <i className="ti-arrow-left"></i>{" "}
                    {intl.formatMessage({ id: "newsandevents.previousbtn" })}
                  </Link>
                )}
              </li>
              <li>
                {Array.from({ length: newsEventNumPages }, (_, i) => {
                  return (
                    <Link
                      key={i}
                      to={`${locale}/news-page/news-event${
                        i === 0 ? "" : i + 1
                      }`}
                    >
                      {i + 1}
                    </Link>
                  )
                })}
              </li>
              <li className="next">
                {!isLast && (
                  <Link to={nextPage}>
                    <i className="ti-arrow-right"></i>{" "}
                    {intl.formatMessage({ id: "newsandevents.nextbtn" })}
                  </Link>
                )}
              </li>
            </ul>
          )}
        </div>
      </NewsEventList>
    </Fragment>
  )
}
export default BlogGrid3Sidebar

export const query = graphql`
  query getNewsEventPost($skip: Int!, $limit: Int!, $reg: String) {
    newsEventsPost: allContentfulNewsevents(
      skip: $skip
      limit: $limit
      sort: { fields: newsEventsDate, order: DESC }
      filter: { node_locale: { regex: $reg } }
    ) {
      edges {
        node {
          slug
          title
          id: contentful_id
          descSingkat
          newsEventsDate(formatString: "DD MMMM YYYY")
          images {
            fluid {
              ...GatsbyContentfulFluid
            }
            gatsbyImageData
          }
          node_locale
        }
      }
    }
    getAllNewsEvents: allContentfulNewsevents(
      sort: { fields: newsEventsDate, order: DESC }
      filter: { node_locale: { regex: $reg } }
    ) {
      edges {
        node {
          slug
          title
          id: contentful_id
          descSingkat
          newsEventsDate(formatString: "DD MMMM YYYY")
          images {
            fluid {
              ...GatsbyContentfulFluid
            }
            gatsbyImageData
          }
          node_locale
        }
      }
    }
    newsEventsCnPost: allContentfulCnNewsevents(
      skip: $skip
      limit: $limit
      sort: { fields: newsEventsDate, order: DESC }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          slug
          title
          id: contentful_id
          descSingkat
          newsEventsDate(formatString: "DD MMMM YYYY")
          images {
            fluid {
              ...GatsbyContentfulFluid
            }
            gatsbyImageData
          }
        }
      }
    }
    getAllNewsEventsCn: allContentfulCnNewsevents(
      sort: { fields: newsEventsDate, order: DESC }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          slug
          title
          id: contentful_id
          descSingkat
          newsEventsDate(formatString: "DD MMMM YYYY")
          images {
            fluid {
              ...GatsbyContentfulFluid
            }
            gatsbyImageData
          }
        }
      }
    }
  }
`
